import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutDashboard from '@/layouts/LayoutDashboard.vue'
import LayoutAuth from '@/layouts/LayoutAuth.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/register',
    name: 'AuthRegister',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthRegister.vue')
  },
  {
    path: '/register-success',
    name: 'AuthRegisterSuccess',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthRegisterSuccess.vue')
  },
  {
    path: '/:role/register',
    name: 'AuthRegisterByRole',
    props: true,
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthRegister.vue')
  },
  {
    path: '/login',
    name: 'AuthLogin',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthLogin.vue')
  },
  {
    path: '/otp-check',
    name: 'AuthOtp',
    meta: { layout: LayoutAuth, auth: true },
    component: () => import('@/views/AuthOtp.vue')
  },
  {
    path: '/email-confirm/:userId/:token',
    name: 'EmailConfirm',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/EmailConfirm.vue')
  },
  {
    path: '/forgot-password',
    name: 'AuthPassForgot',
    meta: { layout: LayoutAuth, auth: false },
    component: () => import('@/views/AuthPassForgot.vue')
  },
  {
    path: '/reset-password/:email/:token',
    name: 'AuthPassReset',
    meta: { layout: LayoutAuth, auth: false },
    props: true,
    component: () => import('@/views/AuthPassReset.vue')
  },
  {
    path: '/business',
    meta: { layout: LayoutDashboard, auth: true },
    component: () => import('@/components/RouteGate.vue'),
    children: [
      {
        path: '',
        name: 'BusinessIndex',
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/BusinessIndex.vue')
      },
      {
        path: 'edit/:id',
        name: 'BusinessEdit',
        props: true,
        meta: { title: 'Edit Business Application', layout: LayoutDashboard, auth: true },
        component: () => import('@/views/BusinessEditor.vue')
      },
      {
        path: 'renew',
        name: 'BusinessRenew',
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/BusinessRenew.vue')
      },
      {
        path: 'renew/:id',
        name: 'BusinessRenewById',
        props: true,
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/BusinessRenew.vue')
      }
    ]
  },
  {
    path: '/permit',
    meta: { layout: LayoutDashboard, auth: true },
    component: () => import('@/components/RouteGate.vue'),
    children: [
      {
        path: '',
        name: 'PermitIndex',
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/PermitIndex.vue')
      },
      {
        path: 'new',
        name: 'PermitNew',
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/PermitEditor.vue')
      },
      {
        path: 'edit/:id',
        name: 'PermitEdit',
        props: true,
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/PermitEditor.vue')
      }
    ]
  },
  {
    path: '/payment',
    name: 'PaymentIndex',
    meta: { layout: LayoutDashboard, auth: true },
    component: () => import('@/views/PaymentIndex.vue')
  },
  {
    name: 'User',
    path: '/user',
    meta: { layout: LayoutDashboard, auth: true },
    component: () => import('@/components/RouteGate.vue'),
    children: [
      {
        path: 'editor',
        name: 'UserEditor',
        meta: { layout: LayoutDashboard, auth: true },
        component: () => import('@/views/UserEditor.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  console.log('at:', to.name)
  console.log('auth:', to.meta.auth)

  // Check for auth of the target route
  const authRequired = to.meta.auth

  // Check for user login
  const isLoggedIn = localStorage.getItem('user')
  console.log('isLoggedIn:', !!isLoggedIn)

  // When the auth is required but the user is not logged in,
  // then send the user back to login screen
  if (authRequired && !isLoggedIn) {
    console.log('forbidden, redirecting...')
    next('/login')
  } else {
    next()
  }
})

export default router
