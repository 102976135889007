<template>
  <div>
    <app-navbar></app-navbar>
    <div class="page-wrapper bg-light">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue'

export default {
  name: 'LayoutDashboard',

  components: {
    AppNavbar
  }
}
</script>

<style>
</style>
