import axios from 'axios'

const instance = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

// Whenever the api response code is 401, redirect user to the login page
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response.status)
    if (error.response.status === 401) {
      window.location = '/login'
    }
    return Promise.reject(error)
  }
)

export default instance
