<template>
  <b-navbar
    toggleable="lg"
    type="light"
    variant="white"
    class="app-navbar border-bottom fixed-top"
  >
    <b-container>
      <!-- Sidebar for mobile view only -->
      <button v-b-toggle.theSidebar class="btn d-lg-none p-0 mr-3">
        <i class="bi-list" style="font-size: 1.5rem"></i>
      </button>
      <b-sidebar
        id="theSidebar"
        title="Menu"
        shadow
        backdrop
        bg-variant="white"
      >
        <div class="px-3 py-2">
          <b-nav vertical>
            <b-nav-item :to="{ name: 'PermitIndex' }">Permit</b-nav-item>
            <b-nav-item-dropdown :text="currentUser" variant="white">
              <b-dropdown-item :to="{ name: 'UserEditor' }"
                >Profile</b-dropdown-item
              >
              <b-dropdown-item @click="logout">Logout</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-nav>
        </div>
      </b-sidebar>

      <b-navbar-brand href="#" class="mr-auto"
        >Business Licensing</b-navbar-brand
      >

      <!-- Right aligned nav items for desktop only-->
      <b-navbar-nav class="ml-auto d-none d-lg-flex">
        <b-nav-item :to="{ name: 'PermitIndex' }">Permit</b-nav-item>
        <b-dropdown right :text="currentUser" variant="white">
          <b-dropdown-item :to="{ name: 'UserEditor' }"
            >Profile</b-dropdown-item
          >
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-dropdown>
      </b-navbar-nav>
    </b-container>
  </b-navbar>
</template>

<script>
  export default {
    name: 'AppNavbar',

    computed: {
      currentUser () {
        return this.$store.state.user?.name
      }
    },

    methods: {
      logout () {
        this.$store.commit('LOGOUT')
      }
    }
  }
</script>

<style>
</style>
