<template>
  <div></div>
</template>

<script>
  export default {
    name: 'AppToast',

    methods: {
      show (data) {
        let variant = ''

        if (data.type === 'success') {
          variant = 'primary'
        } else if (data.type === 'error') {
          variant = 'danger'
        }

        this.$bvToast.toast(data.message, {
          title: 'BPLO',
          toaster: 'b-toaster-bottom-center',
          variant,
          autoHideDelay: 4500,
          appendToast: true
        })
      },

      success (message) {
        this.$bvToast.toast(message, {
          title: 'BPLO',
          toaster: 'b-toaster-bottom-center',
          variant: 'primary',
          autoHideDelay: 4500,
          appendToast: true
        })
      },

      error (message) {
        this.$bvToast.toast(message, {
          title: 'BPLO',
          toaster: 'b-toaster-bottom-center',
          variant: 'danger',
          autoHideDelay: 4500,
          appendToast: true
        })
      }
    }
  }
</script>

<style>
</style>
