<template>
  <div id="app">
    <component :is="$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  name: 'App',

  created () {
    // Persist login after closing the browser
    const userString = localStorage.getItem('user')

    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('SET_USER_DATA', userData)
    }
  }
}
</script>

<style>
  @import "~bootstrap-icons/font/bootstrap-icons.css";
  @import "./assets/css/style.css";
</style>
