<template>
  <div class="bg-white">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'LayoutAuth'
  }
</script>

<style>
</style>
