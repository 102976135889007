<template>
  <div>
    <b-modal
      ref="spinner"
      hide-header
      content-class="bg-transparent border-0"
      body-class="d-flex justify-content-center"
      hide-footer
      no-close-on-backdrop
      centered
    >
      <template #modal-header></template>
      <b-spinner variant="white" style="width: 3rem; height: 3rem"></b-spinner>
      <template #modal-footer></template>
    </b-modal>
  </div>
</template>

<script>
  export default {
    name: 'AppSpinner',

    methods: {
      show () {
        this.$refs.spinner.show()
      },

      hide () {
        this.$refs.spinner.hide()
      }
    }
  }
</script>

<style>
  .spinner-wrapper {
    background-color: blue;
  }
</style>
