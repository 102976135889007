import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/services/setup'

Vue.use(Vuex)

export default new Vuex.Store({

  state: {
    user: null
  },

  mutations: {
    SET_USER_DATA (state, userData) {
      localStorage.setItem('user', JSON.stringify(userData))
      api.defaults.headers.common.Authorization = userData.token
      state.user = userData
    },

    LOGOUT (state) {
      localStorage.removeItem('user')
      api.defaults.headers.common.Authorization = ''
      state.user = null
      window.location.href = '/#/login'
    }
  }

})
